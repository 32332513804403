/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.picky-date-time-calendar__table {
  border-collapse: collapse;
  border-collapse: initial !important;
  display: table;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

/* *{
  outline: 2px solid rgb(29, 124, 29) !important;
} */